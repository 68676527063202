import styled from '@emotion/styled';
import { Card, TitledItem } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

export interface TectonFCOListItem {
  title: React.ReactNode;
  content: React.ReactNode;
}

interface TectonFCOCardProps {
  title: React.ReactNode;
  items: TectonFCOListItem[];
  showOverlay?: boolean;
}

export const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.l};
`;

const TectonFCOCard: FC<TectonFCOCardProps> = ({ title, items, showOverlay = true }) => {
  return (
    <Card title={title} data-testid="tecton-card" showOverlay={showOverlay}>
      <CardTable>
        {items.map((item) => {
          return <TitledItem title={item.title} content={item.content} />;
        })}
      </CardTable>
    </Card>
  );
};

export default TectonFCOCard;
