import { TopBanner } from '@tecton/ComponentRedesign';
import { FC } from 'react';

import MessageIcon from '@svg/message.svg';
import { ReactComponent as RocketIcon } from '@svg/rocket.svg';

const ExploreTopBanner: FC<{ onClick: VoidFunction }> = ({ onClick }) => {
  return (
    <>
      <TopBanner
        variant="success"
        bannerLabel="Demo Account"
        description="You are viewing a real-time fraud detection use case"
        primaryText={'Start Tutorial'}
        primaryIcon={RocketIcon}
        primaryUrl={`https://docs.tecton.ai/docs/beta/tutorials/tecton-quick-start`}
        secondaryText={'Join the Slack Community'}
        secondaryIcon={MessageIcon}
        secondaryUrl={`https://join.slack.com/t/tecton-public/shared_invite/zt-2awg3yl86-VxV6txkC0px3vVraxBasbQ`}
        onCloseClicked={onClick}
      />
    </>
  );
};

export default ExploreTopBanner;
