import styled from '@emotion/styled';
import { Button, DatePicker, Icons, IconTypes, Popover } from '@tecton/ComponentRedesign';
import moment, { Duration, Moment } from 'moment-timezone';
import React, { FC, useState } from 'react';

export interface TimeComboDateRange {
  startDate: Moment;
  endDate: Moment;
}

export interface TimeComboOption {
  label: string;
  duration?: Duration;
}

export type TimeComboOptionType =
  | 'custom'
  | '5Minutes'
  | '15Minutes'
  | '30Minutes'
  | '1Hour'
  | '6Hours'
  | '12Hours'
  | '1Day'
  | '2Days'
  | '3Days'
  | '1Week'
  | '2Weeks'
  | '1Month'
  | '2Months'
  | '6Months';

export const TimeComboOptions: Record<TimeComboOptionType, TimeComboOption> = {
  custom: { label: 'Custom' },
  '5Minutes': { label: 'Past 5 minutes', duration: moment.duration(5, 'minutes') },
  '15Minutes': { label: 'Past 15 minutes', duration: moment.duration(15, 'minutes') },
  '30Minutes': { label: 'Past 30 minutes', duration: moment.duration(30, 'minutes') },
  '1Hour': { label: 'Past hour', duration: moment.duration(1, 'hours') },
  '6Hours': { label: 'Past 6 hours', duration: moment.duration(6, 'hours') },
  '12Hours': { label: 'Past 12 hours', duration: moment.duration(12, 'hours') },
  '1Day': { label: 'Past day', duration: moment.duration(1, 'days') },
  '2Days': { label: 'Past 2 days', duration: moment.duration(2, 'days') },
  '3Days': { label: 'Past 3 days', duration: moment.duration(3, 'days') },
  '1Week': { label: 'Past week', duration: moment.duration(1, 'weeks') },
  '2Weeks': { label: 'Past two weeks', duration: moment.duration(2, 'weeks') },
  '1Month': { label: 'Past month', duration: moment.duration(1, 'months') },
  '2Months': { label: 'Past 2 months', duration: moment.duration(2, 'months') },
  '6Months': { label: 'Past 6 months', duration: moment.duration(6, 'months') },
};

interface TectonTimeSelectorComboOptionProps {
  option: TimeComboOption;
  isSelected?: boolean;
  onClick: () => void;
}

const StyledLabel = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.lightShade : theme.colors.emptyShade)};
  font-weight: ${({ isSelected, theme }) => (isSelected ? theme.font.weight.semiBold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  border-bottom: ${({ theme }) => theme.border.thin};
  padding: ${({ theme }) => theme.padding.xs};

  ${({ isSelected, theme }) =>
    !isSelected &&
    `:hover {
        font-weight: ${theme.font.weight.medium};
        background-color: ${theme.colors.lightestShade};
      }`}
`;

export const TectonTimeSelectorComboOption: FC<TectonTimeSelectorComboOptionProps> = ({
  option,
  isSelected,
  onClick,
}) => {
  return (
    <StyledLabel onClick={onClick} isSelected={isSelected}>
      {option.label}
    </StyledLabel>
  );
};

interface TectonTimeSelectorCustomRangePanelProps {
  startDate: Moment;
  endDate: Moment;
  onCustomRangeSelected: (startDate: Moment, endDate: Moment) => void;
}

const RangePanel = styled.div`
  padding-left: ${({ theme }) => theme.padding.l};
  width: 100%;
  border-left: ${({ theme }) => theme.border.thin};
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.padding.s};
`;

export const TectonTimeSelectorCustomRangePanel: FC<TectonTimeSelectorCustomRangePanelProps> = ({
  startDate,
  endDate,
  onCustomRangeSelected,
}) => {
  const [internalStart, setInternalStart] = useState<Moment>(startDate);
  const [internalEnd, setInternalEnd] = useState<Moment>(endDate);

  const hasDateChanged = internalStart === startDate && internalEnd === endDate;

  return (
    <RangePanel data-testid="time-combo-selector-date-pickers">
      <DatePicker
        label={<>Start Date</>}
        date={internalStart}
        setDate={setInternalStart}
        dateAndTime
        data-testid="time-combo-start-date-picker"
        // We only allow 6 months history
        minDate={moment().local().subtract(6, `months`).startOf('month')}
        maxDate={moment().local().startOf('day')}
      />
      <DatePicker
        label={<>End Date</>}
        date={internalEnd}
        setDate={setInternalEnd}
        dateAndTime
        data-testid="time-combo-end-date-picker"
        // We only allow 6 months history
        minDate={moment().local().subtract(6, `months`).startOf('month')}
        maxDate={moment().local().startOf('day')}
      />
      <ButtonDiv>
        <Button
          variant={hasDateChanged ? 'disabledAction' : 'primaryAction'}
          label={'Select Range'}
          onClick={() => {
            onCustomRangeSelected(internalStart, internalEnd);
          }}
        />
      </ButtonDiv>
    </RangePanel>
  );
};

interface TimeSelectorOptionPanelProps {
  selection: TimeComboOptionType;
  onSelection: (option: TimeComboOptionType) => void;
}

const PanelDiv = styled.div`
  width: 200px;
  padding-right: ${({ theme }) => theme.padding.l};
  height: 100%;
  overflow: scroll;
`;

export const TimeSelectorOptionPanel: FC<TimeSelectorOptionPanelProps> = ({ selection, onSelection }) => {
  return (
    <PanelDiv data-testid="time-combo-selector-option-panel">
      {Object.keys(TimeComboOptions).map((option) => {
        return (
          <TectonTimeSelectorComboOption
            option={TimeComboOptions[option as TimeComboOptionType]}
            isSelected={selection === option}
            onClick={() => {
              onSelection(option as TimeComboOptionType);
            }}
          />
        );
      })}
    </PanelDiv>
  );
};

interface TectonTimeSelectorComboPanelProps {
  selection: TimeComboOptionType;
  startDate: Moment;
  endDate: Moment;
  onSelection: (option: TimeComboOptionType) => void;
  isCustomPanelOpen: boolean;
  setIsCustomPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCustomRangeSelected: (startDate: Moment, endDate: Moment) => void;
}

const SelectorPanel = styled.div<{ isCustomPanelOpen?: boolean }>`
  width: ${({ isCustomPanelOpen }) => (isCustomPanelOpen ? '600px' : '200px')};
  display: flex;
  padding: ${({ theme }) => theme.padding.s};
  height: 200px;
  overflow: hidden;
  border: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.emptyShade};
`;

export const TectonTimeComboSelectorPanel: FC<TectonTimeSelectorComboPanelProps> = ({
  selection,
  startDate,
  endDate,
  onSelection,
  isCustomPanelOpen,
  onCustomRangeSelected,
}) => {
  return (
    <SelectorPanel data-testid="time-combo-selector-panel" isCustomPanelOpen={isCustomPanelOpen}>
      <TimeSelectorOptionPanel selection={selection} onSelection={onSelection} />
      {isCustomPanelOpen && (
        <TectonTimeSelectorCustomRangePanel
          startDate={startDate}
          endDate={endDate}
          onCustomRangeSelected={onCustomRangeSelected}
        />
      )}
    </SelectorPanel>
  );
};

interface TectonTimeComboSelectorButtonProps {
  label: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonWrapper = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  align-self: stretch;
  padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.s};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => theme.border.thin};
  background: ${({ theme }) => theme.colors.emptyShade};
  gap: 4px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 160px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.disabledText : theme.colors.text)};
`;

const LabelWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
`;

export const TectonTimeComboSelectorButton: FC<TectonTimeComboSelectorButtonProps> = ({ label, onClick, disabled }) => {
  return (
    <ButtonWrapper disabled={disabled} onClick={onClick} data-testid="combo-selector-button">
      {Icons[IconTypes.CALENDAR]}
      <LabelWrapper>{label}</LabelWrapper>
      {Icons[IconTypes.CHEVRON_DOWN]}
    </ButtonWrapper>
  );
};

interface TectonTimeComboSelectorProps {
  dateRange: TimeComboDateRange;
  setDateRange: (range: TimeComboDateRange) => void;
  initialOption?: TimeComboOptionType;
  onComboSelection?: (timeComboOption: TimeComboOptionType) => void;
  onCustomDateSelection?: (startDate: Moment, endDate: Moment) => void;
}

const TectonTimeComboSelector: FC<TectonTimeComboSelectorProps> = ({
  initialOption,
  dateRange,
  setDateRange,
  onComboSelection,
  onCustomDateSelection,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCustomPanelOpen, setIsCustomPanelOpen] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<TimeComboOptionType>(initialOption ?? 'custom');

  const onTimeComboSelected = (option: TimeComboOptionType) => {
    if (option === 'custom') {
      setIsCustomPanelOpen(true);
      return;
    }

    setDateRange({
      startDate: moment().subtract(TimeComboOptions[option].duration!),
      endDate: moment(),
    });
    setCurrentOption(option);
    setIsCustomPanelOpen(false);
    setIsOpen(false);
    onComboSelection?.call(this, option);
  };

  const onCustomRangeSelected = (startDate: Moment, endDate: Moment) => {
    setCurrentOption('custom');
    setDateRange({ startDate: startDate, endDate: endDate });
    setIsOpen(false);
    onCustomDateSelection?.call(this, startDate, endDate);
  };

  return (
    <Popover
      data-testid="time-combo-selector"
      button={
        <TectonTimeComboSelectorButton
          label={TimeComboOptions[currentOption].label}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      }
      maxWidth="600px"
      isOpen={isOpen}
      body={
        <TectonTimeComboSelectorPanel
          selection={currentOption}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onSelection={onTimeComboSelected}
          isCustomPanelOpen={isCustomPanelOpen}
          setIsCustomPanelOpen={setIsCustomPanelOpen}
          onCustomRangeSelected={onCustomRangeSelected}
        />
      }
      closePopover={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default TectonTimeComboSelector;
