import styled from '@emotion/styled';
import { ButtonIcon, ContentBodyWrapper, ContentWrapper, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import React, { FC, ReactNode } from 'react';

import { ReactComponent as ExitIcon } from '@svg/exit.svg';

const FlyoutWrapper = styled.div`
  max-width: 610px;
  margin-left: ${({ theme }) => theme.padding.s};
`;

const FlyoutHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: ${({ theme }) => theme.padding.xl} ${({ theme }) => theme.padding.xl};
  border-radius: ${({ theme }) => theme.padding.xl} ${({ theme }) => theme.padding.xl} 0px 0px;
`;

const FlyoutHeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gap.s};
`;

const FLyoutBodyWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: ${({ theme }) => theme.padding.xl} ${({ theme }) => theme.padding.xl};
  height: 100vh;
  overflow: scroll;
`;

export interface FlyoutContentProps {
  headerContent: ReactNode;
  bodyContent: ReactNode;
  onCloseClicked?: VoidFunction;
}

const FlyoutContent: FC<FlyoutContentProps> = ({ headerContent, bodyContent, onCloseClicked }) => {
  return (
    <FlyoutWrapper>
      <FlexGroupWrapper gap="xxs" direction="column">
        <FlexItem grow={false}>
          <FlyoutHeaderWrapper>
            <FlexGroupWrapper gap="xxs" direction="row" justifyContent="spaceBetween" alignItems="center">
              <FlexItem grow={false}>
                <FlyoutHeaderLeftContent>{headerContent}</FlyoutHeaderLeftContent>
              </FlexItem>
              <FlexItem grow={false}>
                <ButtonIcon iconType={ExitIcon} onClick={onCloseClicked} />
              </FlexItem>
            </FlexGroupWrapper>
          </FlyoutHeaderWrapper>
        </FlexItem>
        <FlexItem grow={false}>
          <FLyoutBodyWrapper>{bodyContent}</FLyoutBodyWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </FlyoutWrapper>
  );
};

interface ViewLayoutProps {
  header: React.ReactNode;
  body: React.ReactNode;
  flyoutContent?: FlyoutContentProps;
}

const ViewLayoutWithFlyoutPush: FC<ViewLayoutProps> = ({ header, body, flyoutContent }) => {
  return (
    <FlexGroupWrapper gap="none" direction="row">
      <FlexItem>
        <ContentWrapper data-testid="view-layout-flyout-push">
          {header}
          <ContentBodyWrapper>{body}</ContentBodyWrapper>
        </ContentWrapper>
      </FlexItem>
      {flyoutContent && (
        <FlexItem grow={false}>
          <>
            <FlyoutContent
              headerContent={flyoutContent?.headerContent}
              bodyContent={flyoutContent?.bodyContent}
              onCloseClicked={flyoutContent?.onCloseClicked}
            />
          </>
        </FlexItem>
      )}
    </FlexGroupWrapper>
  );
};

export default ViewLayoutWithFlyoutPush;
