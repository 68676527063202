import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useGetGlobalsForWebUi } from '../../api/user';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { useGetAuthorizedResources, useGetIsAuthorize } from '../../api/acl';
import { WorkspaceWithPermissions } from './types';
import WorkspaceUtils from '../../utils/workspace-utils';
import OktaAuth, { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { useWorkspaceList } from '../../shared/query';
import { WorkspaceCapabilities } from '../../types/tecton_proto/data/workspace';
import { Workspace } from '@tecton/ComponentRedesign';

// FEATURE FLAGS AVAILABLE IN UI
export enum FeatureFlags {
  SHOW_ACTIVATION_ONBOARDING = 'SHOW_ACTIVATION_ONBOARDING',
  FORCE_ONBOARDING_STATE_VALUE = 'FORCE_ONBOARDING_STATE_VALUE',
  HAS_SEEN_ONBOARDING_WELCOME_PAGE = 'HAS_SEEN_ONBOARDING_WELCOME_PAGE',
  INTERNAL_DEBUG_SHOW_CONSUMPTION_INFO = 'INTERNAL_DEBUG_SHOW_CONSUMPTION_INFO',
  SHOW_NEW_FCO_LISTS = 'SHOW_NEW_FCO_LISTS',
  SHOW_NEW_UI_FV_DETAILS = 'SHOW_NEW_UI_FV_DETAILS',
  SHOW_NEW_TOP_NAV = 'SHOW_NEW_TOP_NAV',
  SHOW_NEW_FEATURE_SERVICES = 'SHOW_NEW_FEATURE_SERVICES',
  SHOW_NEW_WORKSPACE_HOME = 'SHOW_NEW_WORKSPACE_HOME',
  SHOW_JOB_DETAIL_EXTERNAL_URL = 'SHOW_JOB_DETAIL_EXTERNAL_URL',
  SHOW_JOB_LOGS = 'SHOW_JOB_LOGS',
  JOBS_GQL = 'JOBS_GQL',
  FCOS_LEVEL_DATAFLOW_DIAGRAMS = 'FCOS_LEVEL_DATAFLOW_DIAGRAMS',
  UI_REDESIGN = 'UI_REDESIGN',
}

// CURRENT VERSION OF FLAG
export enum FeatureFlagVersion {
  // HIDDEN
  SHOW_ACTIVATION_ONBOARDING = 'SHOW_ACTIVATION_ONBOARDING',
  FORCE_ONBOARDING_STATE_VALUE = 'FORCE_ONBOARDING_STATE_VALUE',
  HAS_SEEN_ONBOARDING_WELCOME_PAGE = 'HAS_SEEN_ONBOARDING_WELCOME_PAGE',
  // DEV
  INTERNAL_DEBUG_SHOW_CONSUMPTION_INFO = 'INTERNAL_DEBUG_SHOW_CONSUMPTION_INFO',
  SHOW_JOB_LOGS = 'SHOW_JOB_LOGS',
  SHOW_JOB_DETAIL_EXTERNAL_URL = 'SHOW_JOB_DETAIL_EXTERNAL_URL',
  UI_REDESIGN = 'UI_REDESIGN_BETA_EXPLORE',
  //TEST
  FCOS_LEVEL_DATAFLOW_DIAGRAMS = 'FCOS_LEVEL_DATAFLOW_DIAGRAMS_TEST',
  // GA
  SHOW_NEW_TOP_NAV = 'SHOW_NEW_TOP_NAV_GA',
  SHOW_NEW_WORKSPACE_HOME = 'SHOW_NEW_WORKSPACE_HOME_GA',
  JOBS_GQL = 'JOBS_GQL_GA',
  SHOW_NEW_FEATURE_SERVICES = 'SHOW_NEW_FEATURE_SERVICES_GA',
  SHOW_NEW_FCO_LISTS = 'SHOW_NEW_FCO_LISTS_GA',
  SHOW_NEW_UI_FV_DETAILS = 'SHOW_NEW_UI_FV_DETAILS_GA',
}

type FeatureFlagDetail = {
  name: FeatureFlags;
  id: FeatureFlagVersion;
};

type FeatureFlagMapProps = {
  [key in FeatureFlags]: FeatureFlagDetail;
};

// Feature Flag Map generated from enums above.
const generateFeatureFlagMap = (): FeatureFlagMapProps => {
  return Object.keys(FeatureFlags).reduce((acc, key) => {
    const flagKey = key as keyof typeof FeatureFlags;
    const version = FeatureFlagVersion[flagKey];
    acc[FeatureFlags[flagKey]] = {
      name: FeatureFlags[flagKey],
      id: version,
    };
    return acc;
  }, {} as FeatureFlagMapProps);
};

export const featureFlagMap = generateFeatureFlagMap();

export const HiddenModalFlags = new Set([
  FeatureFlags.FORCE_ONBOARDING_STATE_VALUE,
  FeatureFlags.SHOW_ACTIVATION_ONBOARDING,
  FeatureFlags.HAS_SEEN_ONBOARDING_WELCOME_PAGE,
]);

const getWorkspaceDetails = (workspace?: WorkspaceWithPermissions): Workspace => {
  return {
    name: workspace?.name ?? '',
    workspaceType: workspace?.capabilities?.materializable ? 'Live' : 'Development',
    isAccessible: workspace?.isAccessible ?? false,
  };
};

export interface UserSettingsProps {
  workspace?: string;
  workspaceDetails?: Workspace;
  hasWorkspaceManagerAccess?: boolean;
  isAdmin?: boolean;
  hasOrganizationListAccess?: boolean;
  canUpdateMaterialization?: boolean;
  allWorkspaces?: WorkspaceWithPermissions[];
  hasMadeTheCallToGetAllWorkspaces?: boolean;
  hasAccessToProdWorkspace?: boolean;
  featureEnabled: (feature: FeatureFlags) => boolean;
  updateWorkspace: (workspace: string) => void;
  updatePrincipal?: (oktaUser: UserClaims<CustomUserClaims> | undefined) => void;

  user?: UserClaims<CustomUserClaims> | undefined;
  isPublicFreeTrial: boolean;
  isStreamingIngestEnabled: boolean;
  hasNoWorkspaceAssigned: boolean;
  canShowMainApplication: boolean;
  updateOktaAuth: (oktaUser: OktaAuth) => void;
  oktaAuth?: OktaAuth; // need for logout. It's kind of like their context
}

enum WORKSPACE_PRINCIPAL_ACTION {
  // TODO We need to add all the actions from current.zed file
  WORKSPACE_MANAGER_ACCESS = 'manage_access',
  WORKSPACE_UPDATE_MATERIALIZATION_STATE = 'update_materialization_state',
}

enum ORGANIZATION_PRINCIPAL_ACTION {
  IS_ADMIN_ACCESS = 'admin',
  LIST_ACCESS = 'list_access',
}

export interface UserSettingsContextProps {
  children: ReactNode;
}

export const UserSettingsContext = createContext<UserSettingsProps | undefined>(undefined);

export const UserSettingsContextProvider = ({ children }: UserSettingsContextProps) => {
  const { workspace: workspaceParams } = useParams();
  const [user, setUser] = useState<UserClaims<CustomUserClaims> | undefined>();
  const [oktaAuth, setOktaAuth] = useState<OktaAuth | undefined>();
  const isUserAvailable = !!user?.sub;
  const mostRecentlyViewedWorkspace = localStorage.getItem('MOST_RECENTLY_VIEWED_WORKSPACE') ?? undefined;
  const [workspace, setWorkspace] = useState<string | undefined>(mostRecentlyViewedWorkspace);
  const [workspaceDetails, setWorkspaceDetails] = useState<Workspace | undefined>();
  const { data: allWorkspacesData, isSuccess: isSuccessAllWorkspaces } = useWorkspaceList(!!user);

  // Don't make the call unless we are logged in.
  const { data, isError } = useGetGlobalsForWebUi(isUserAvailable);

  // get a list of workspaces to which the logged in user has read access to.
  const { data: permissionsData } = useGetAuthorizedResources({
    resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
    action: 'read_config',
    principal_type: PrincipalType.PRINCIPAL_TYPE_USER,
    principal_id: user?.sub,
  });

  const allWorkspaces: WorkspaceWithPermissions[] =
    allWorkspacesData?.workspaces?.map((apiWorkspace: { name: string; capabilities: WorkspaceCapabilities }) => {
      const checkPermissions =
        permissionsData?.authorized_resources?.filter((userWorkspace) => {
          return userWorkspace.resource_id === apiWorkspace.name;
        }) ?? [];

      return {
        name: apiWorkspace.name,
        capabilities: apiWorkspace.capabilities,
        isAccessible: checkPermissions.length === 1,
        permissions: checkPermissions.length === 1 ? checkPermissions[0].actions : [],
      };
    }) ?? [];

  const hasAccessToProdWorkspace = !!allWorkspaces?.find((ws) => {
    return ws.name === 'prod' && ws.isAccessible;
  });

  // Let's create the workspaceDetails which includes live/dev  and isAccessible
  if (workspace) {
    const wsDetails = allWorkspaces?.find((ws) => ws.name === workspace);

    if (isSuccessAllWorkspaces || workspace !== workspaceDetails?.name) {
      if (!workspaceDetails && wsDetails) {
        setWorkspaceDetails(getWorkspaceDetails(wsDetails));
      }
    }
  }

  const hasNoWorkspaceAssigned = allWorkspaces?.filter((workspace: any) => workspace.isAccessible).length === 0;

  // MDS will send feature flag id
  // local storage will save feature flag id
  // we will use feature flag name to reference the flag in the app
  const featureEnabled = (feature: FeatureFlags) => {
    if (isError) return false;
    const flagId = featureFlagMap[feature].id;
    const storedFlag = localStorage.getItem(flagId);
    if (storedFlag) {
      // If we find something in local storage, let's use it.
      return storedFlag?.toLowerCase() === 'true';
    }
    // next we look for flag in the payload
    return data?.key_values?.[flagId] === 'true';
  };

  const updateWorkspace = useCallback(
    (ws: string) => {
      if (workspace !== ws) {
        setWorkspace(ws);
        const wsDetails = allWorkspaces?.find((workspace) => workspace.name === ws);

        if (wsDetails) {
          // Let's make sure we find a workspace before we set it in localStorage.
          // User can get into a really bad state if the workspace doesn't exist
          WorkspaceUtils.setWorkspaceForLocalStorage(ws);
          WorkspaceUtils.addWorkspaceToRecent(wsDetails.name);
        }

        if (workspaceDetails) {
          setWorkspaceDetails(getWorkspaceDetails(wsDetails));
        }
      }
    },
    [workspace, setWorkspace, allWorkspaces]
  );

  const updateUser = (oktaUser: UserClaims<CustomUserClaims> | undefined) => {
    // Update call should only set once
    if (user === undefined) {
      setUser(oktaUser);
    }
  };

  const updateOktaAuth = (oktaAuth: OktaAuth) => {
    if (oktaAuth) {
      setOktaAuth(oktaAuth);
    }
  };

  // Workspace Permissions
  // We need both the user and workspace to be available in order for us to make the call
  const isWorkspaceGetIsAuthorizedEnabled = isUserAvailable && workspace !== undefined;
  const workspacePermissions = Object.values(WORKSPACE_PRINCIPAL_ACTION).map((action) => {
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;
    const resource_id = workspace;

    return {
      resource_type,
      resource_id,
      action,
    };
  });

  const isWorkspaceManageAccessPayload = {
    principal_type: PrincipalType.PRINCIPAL_TYPE_USER,
    principal_id: user?.sub ?? '',
    permissions: workspacePermissions,
  };

  const { data: getWorkspaceIsAuthorizedData } = useGetIsAuthorize(
    isWorkspaceGetIsAuthorizedEnabled,
    isWorkspaceManageAccessPayload
  );

  // Organization Permission
  // We only need the user to make the call
  const isOrganizationGetIsAuthorizedEnabled = isUserAvailable === true;
  const organizationPermissions = Object.values(ORGANIZATION_PRINCIPAL_ACTION).map((action) => {
    const resource_type = ResourceType.RESOURCE_TYPE_ORGANIZATION;
    return {
      resource_type,
      action,
    };
  });

  const isOrganizationManageAccessPayload = {
    principal_type: PrincipalType.PRINCIPAL_TYPE_USER,
    principal_id: user?.sub ?? '',
    permissions: organizationPermissions,
  };

  const { data: getOrganizationIsAuthorizedData } = useGetIsAuthorize(
    isOrganizationGetIsAuthorizedEnabled,
    isOrganizationManageAccessPayload
  );

  const isPublicFreeTrial = data?.key_values?.[`PUBLIC_FREE_TRIAL`] === 'true';
  // Is the Streaming Ingest enabled at the cluster level?
  const isStreamingIngestEnabled = data?.key_values?.[`ENABLE_PUSH_API`] === 'true';

  const workspaces = allWorkspaces ?? [];
  const workspaceToTry = workspace ?? workspaceParams ?? undefined;
  const containsWorkspaces = workspaces.length > 0;
  const foundWorkspaceAccess = workspaces?.find((ws: WorkspaceWithPermissions) => {
    return ws.name === workspaceToTry;
  });

  let canShowMainApplication = true;

  if (hasNoWorkspaceAssigned) {
    // no assigned workspace
    canShowMainApplication = false;
  }

  if (workspace !== undefined && foundWorkspaceAccess?.isAccessible === false && containsWorkspaces) {
    // we found the workspace, but you don't have access to it.
    canShowMainApplication = false;
  }

  if (!hasNoWorkspaceAssigned && workspaceToTry === undefined && containsWorkspaces) {
    // could be your first time logging in and we couldn't find a workspace in your localStorage
    canShowMainApplication = false;
  }

  if (foundWorkspaceAccess === undefined) {
    // no workspace found
    canShowMainApplication = false;
  }

  if (hasAccessToProdWorkspace) {
    canShowMainApplication = true;
  }

  // Define the context value.
  const contextValue: UserSettingsProps = {
    workspace,
    workspaceDetails,
    allWorkspaces,
    hasAccessToProdWorkspace,
    hasWorkspaceManagerAccess:
      getWorkspaceIsAuthorizedData?.includes(WORKSPACE_PRINCIPAL_ACTION.WORKSPACE_MANAGER_ACCESS) ?? false,
    isAdmin: getOrganizationIsAuthorizedData?.includes(ORGANIZATION_PRINCIPAL_ACTION.IS_ADMIN_ACCESS) ?? false,
    hasOrganizationListAccess:
      getOrganizationIsAuthorizedData?.includes(ORGANIZATION_PRINCIPAL_ACTION.LIST_ACCESS) ?? false,
    // Does the user have access to hit retry on a materialization job?
    canUpdateMaterialization:
      getWorkspaceIsAuthorizedData?.includes(WORKSPACE_PRINCIPAL_ACTION.WORKSPACE_UPDATE_MATERIALIZATION_STATE) ??
      false,
    hasMadeTheCallToGetAllWorkspaces: isSuccessAllWorkspaces,
    featureEnabled,
    updateWorkspace,
    updatePrincipal: updateUser,
    updateOktaAuth,
    canShowMainApplication,
    user,
    isPublicFreeTrial,
    isStreamingIngestEnabled,
    hasNoWorkspaceAssigned,
    oktaAuth,
  };

  return <UserSettingsContext.Provider value={contextValue}>{children}</UserSettingsContext.Provider>;
};

// Create a custom hook to easily access the UserSettingsContext.
export const useUserSettings = (): UserSettingsProps => {
  const context = useContext(UserSettingsContext);
  if (context === undefined) {
    throw new Error('useUserSettings must be used within a UserSettingsContextProvider');
  }
  return context;
};
